import $ from 'jquery';
import { bindJQuery } from '@/scripts/helpers/library';
import { addedLazyLoadImages } from '@/scripts/helpers/lazyload';
import {BREAKPOINTS, CLASSES} from '@/scripts/helpers/constants';
import 'slick-carousel/slick/slick';
import lazyframe from 'lazyframe';
import video from '../modules/containers/video';
import { accordion as initAccordion } from '../modules/containers/accordion/index';
import { detectBrowser } from '@/scripts/helpers/detectBrowser';

const { IS_OPEN } = CLASSES;
const { DESKTOP, DESKTOP_MD } = BREAKPOINTS;

const globalErrorHandler = event => {
  const body = {
    message: 'js error',
    level: 'error',
    data: {
      referrer: window.location.href,
      userAgent: window.navigator.userAgent,
      guestId: window.guestId,
      eventMessage: event.message,
      stackTrace: event.error.stack,
    },
  };
  const ajax = new XMLHttpRequest();

  ajax.open('POST', '/logger', true);
  ajax.setRequestHeader('Content-Type', 'application/json');
  ajax.send(JSON.stringify(body));
};

function scriptOnError(script) {
  const event = {
    message: `script error: ${script.src}`,
    error: { stack: '' },
  };

  globalErrorHandler(event);
}
let isGoToProductClicked = window.isGoToProduct || false;

function gotoProduct(event, url, id) {
  event.preventDefault();
  const that = this;

  // to prevent double click
  if (isGoToProductClicked) return false;

  isGoToProductClicked = true;

  setTimeout(() => {
    isGoToProductClicked = false;
  }, 3000);

  $.ajax({
    url: '/click-event/click',
    type: 'POST',
    data: { event: 'click' },
  }).done(() => {
    const formId = id ? id : that.getAttribute('data-sender-id');
    const redirectUrl = (!url && window.redirectLink) ? window.redirectLink : null;

    if (dataLayer !== 'undefined') {
      dataLayer.push({
        event: 'CustomEvent',
        eventCategory: formId,
        eventAction: 'click',
        eventLabel: 'topdffiller',
      });
    }

    if (typeof sksTracker !== 'undefined') {
      sksTracker.trackEdit();
    }
    window.location.href = redirectUrl;
  });
}

function logConv(event, url, id) {
  const data = {
    message: 'wizzard_conversion',
    level: 'warning',
  };
  if (isGoToProductClicked) {
    return false;
  }
  isGoToProductClicked = true;
  setTimeout(() => {
    isGoToProductClicked = false;
  }, 3000);

  $.ajax({
    url: '/logger',
    type: 'POST',
    data,
  });
}

$.ajax({
  url: '/click-event/click',
  type: 'POST',
  data: { event: 'visit' },
});

function submitWizzard(e) {
  if ($(e.target).closest('.cta-button').length) {
    $('.form-submit').click();
  }
}
$(document).on('click', submitWizzard);

const handleLoader = () => {
  const selector = {
    loaderTrigger: '[data-loader]',
    percentage: '.percentage',
    loader: '.js-loader',
    circle: '.loader .circle',
  };

  function showLoader() {
    $(selector.loader).addClass('is-show');
  }

  function animateCount(el) {
    $(el).each(function () {
      const $this = $(this);
      $({ Counter: 0 }).animate(
        {
          Counter: $this.text(),
        },
        {
          duration: 2800,
          easing: 'swing',
          step() {
            $this.text(`${Math.ceil(this.Counter)}%`);
          },
        },
      );
    });
  }

  function animateCircle(el) {
    $(el).each(function () {
      const $this = $(this);
      $({ Counter: 100 }).animate(
        {
          Counter: $this.attr('stroke-dashoffset'),
        },
        {
          duration: 2800,
          easing: 'swing',
          step() {
            $this.attr('stroke-dashoffset', this.Counter);
          },
        },
      );
    });
  }

  $(selector.loaderTrigger).on('click', (e) => {
    e.preventDefault();

    if ($(selector.loader).length) {
      // if loader markup exist in DOM
      showLoader();
      animateCircle(selector.circle);
      animateCount(selector.percentage);
      $('body').addClass('modal-open');
      $('body').keyup((event) => {
        if (event.keyCode === 27 || event.which === 27) {
          event.preventDefault();
        }
      });
    }
  });

  window.addEventListener(
    'pageshow',
    (event) => {
      if (event.persisted) window.location.reload();
    },
    false,
  );
};

const initBenefitFromCarousel = () => {
  const benefitFromCarousel = $('.js-benefit-from-carousel');
  const benefitFromCarouselredesign = $('.js-benefit-from-carousel-redesign');
  const windowWidth = $(window).width();
  const destroyBreakpoint = 830;
  const destroyBreakpointRedesign = 750;

  if (windowWidth > destroyBreakpoint) {
    if (benefitFromCarousel.hasClass('slick-initialized')) {
      benefitFromCarousel.slick('unslick');
    }
  } else if (benefitFromCarousel.length) {
    benefitFromCarousel.not('.slick-initialized').slick({
      dots: true,
      arrows: false,
      cssEase: 'linear',
      speed: 150,
      autoplaySpeed: 4000,
      autoplay: false,
      infinite: true,
      centerMode: true,
      variableWidth: true,
      pauseOnHover: true,
      pauseOnFocus: false,
      pauseOnDotsHover: !(
        !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
      ),
    });
  }

  if (windowWidth > destroyBreakpointRedesign) {
    if (benefitFromCarouselredesign.hasClass('slick-initialized')) {
      benefitFromCarouselredesign.slick('unslick');
    }
  } else if (benefitFromCarouselredesign.length) {
    benefitFromCarouselredesign.not('.slick-initialized').slick({
      dots: true,
      arrows: false,
      cssEase: 'linear',
      speed: 150,
      autoplaySpeed: 4000,
      autoplay: false,
      infinite: true,
      variableWidth: true,
      pauseOnHover: true,
      pauseOnFocus: false,
      pauseOnDotsHover: !(
        !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
      ),
    });
  }
};

// legacy accordion
// todo avoid usage
const accordion = $('.js-accordion .accordion-item');
const accordionItemTitle = $('.js-accordion .accordion-item__head');

function openAccordion() {
  const animationTime = 0;
  accordion.not($(this)).removeClass('is-active');
  $(this).toggleClass('is-active');
  accordion
    .not($(this))
    .find('.accordion-item__body')
    .slideUp(animationTime);
  $(this)
    .find('.accordion-item__body')
    .slideToggle(animationTime);
}

accordionItemTitle.on('focus', (e) => {
  accordionItemTitle.removeAttr('style');
});

accordion.on('click', function (e) {
  $(e.target).css('outline', '0');
  openAccordion.call(this);
});

accordion.on('keydown', (e) => {
  if (e.key === 'Enter') {
    openAccordion.call(e.target.parentNode);
  }
});

$(window).on('load resize', () => {
  initBenefitFromCarousel();
});

const showMore = () => {
  if ($('.js-show-more').length) {
    $('.js-show-more').on('click', function () {
      $(this).text($(this).text() === 'Show details' ? 'Hide details' : 'Show details');
      $(this).parent().toggleClass(IS_OPEN);
      const hiddenContent = $(this).parent().prev();
      const innerHeight = hiddenContent.find('.section-content__inner').outerHeight();
      hiddenContent.toggleClass(IS_OPEN);

      if (hiddenContent.hasClass(IS_OPEN)) {
        hiddenContent.css({ height: innerHeight });
      } else {
        hiddenContent.removeAttr('style');
      }
    });
  }
};

const initTrustSlider = () => {
  const trustSldier = $('.js-trust-slider');

  if (trustSldier.length) {
    trustSldier.not('.slick-initialized').slick({
      variableWidth: true,
      slidesToShow: 6,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            autoplay: true,
            variableWidth: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            swipeToSlide: true,
            pauseOnHover: false,
            arrows: false,
            fade: false,
            infinite: true,
            dots: false,
            autoplaySpeed: 0,
            speed: 6000,
            cssEase: 'linear',
            useTransform: false,
          },
        },
      ],
    });
  }
};

const initReviewsSlider = () => {
  const otherFormsSlider = $('.js-reviews-slider');
  const prevArrow = `<button type="button" class="slider-arrow slider-arrow--prev"></button>`;
  const nextArrow = `<button type="button" class="slider-arrow slider-arrow--next"></button>`;

  if (otherFormsSlider.length) {
    otherFormsSlider.not('.slick-initialized').slick({
      infinite: true,
      focusOnSelect: false,
      pauseOnFocus: false,
      arrows: true,
      prevArrow,
      nextArrow,
      cssEase: 'linear',
      variableWidth: false,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: DESKTOP,
          settings: {
            slidesToShow: 1,
            variableWidth: false,
          },
        },
      ],
    });
  }
};

const initFormsSlider = () => {
  const otherFormsSlider = $('.js-popular-forms-slider');
  const prevArrow = `<button type="button" class="slider-arrow slider-arrow--prev"></button>`;
  const nextArrow = `<button type="button" class="slider-arrow slider-arrow--next"></button>`;

  if (otherFormsSlider.length) {
    otherFormsSlider.not('.slick-initialized').slick({
      infinite: true,
      focusOnSelect: false,
      pauseOnFocus: false,
      arrows: true,
      prevArrow,
      nextArrow,
      cssEase: 'linear',
      slidesToShow: 8,
      slidesToScroll: 1,
      variableWidth: true,
      responsive: [
        {
          breakpoint: DESKTOP_MD,
          settings: {
            slidesToShow: 1,
            arrows: true,
            prevArrow,
            nextArrow,
            variableWidth: true,
          },
        },
      ],
    });
  }
};

const initRatingBlock = () => {
  const rating = $('.reiting');
  const ratingStars = rating.find('.reiting__star');

  const handleHoverStar = function () {
    const index = $(this).index();
    for (let i = 0; i <= index; i += 1) {
      $(ratingStars[i]).addClass('is-active');
    }
  };

  const handleBlurStar = function () {
    ratingStars.removeClass('is-active');
  };

  const handleClickStar = function () {
    const index = $(this).index();
    ratingStars.removeClass('is-half-filled is-filled').addClass('star--empty');

    for (let i = 0; i <= index; i += 1) {
      $(ratingStars[i]).removeClass('star--empty').addClass('is-filled');
    }

    localStorage.setItem('rating-star', index + 1);
  };

  ratingStars.hover(handleHoverStar, handleBlurStar);
  ratingStars.click(handleClickStar);
};

$(document).ready(() => {
  addedLazyLoadImages();
  bindJQuery();
  handleLoader();
  video();
  initAccordion();
  detectBrowser();
  showMore();
  initRatingBlock();

  const elements = $('.video-player__lazyframe');

  lazyframe(elements, {
    onAppend: iframe => {
      $(iframe).attr('allow', 'autoplay');
    },
  });

  function showDetails() {
    $('.js-section-details').toggleClass('is-open');
  }
  $('.js-details-show, .js-details-hide').on('click', showDetails);

  Array.prototype.forEach.call(
    document.querySelectorAll('[data-sender-id]'),
    (element) => {
      element.addEventListener('click', gotoProduct);
      element.addEventListener('keydown', (e) => {
        if (e.key === 'Enter') {
          gotoProduct.call(e.target);
        }
      });
    },
  );
  Array.prototype.forEach.call(
    document.querySelectorAll('.button.form-submit'),
    (element) => {
      element.addEventListener('click', logConv);
      element.addEventListener('keydown', (e) => {
        if (e.key === 'Enter') {
          logConv.call(e.target);
        }
      });
    },
  );

  /* MPS-214 */
  initFormsSlider();
  initReviewsSlider();
  initTrustSlider();


  $(window).on( "resize", function() {
    initFormsSlider();
    initReviewsSlider();
    initTrustSlider();
  });
});

window.globalErrorHandler = globalErrorHandler;
window.scriptOnError = scriptOnError;
window.addEventListener('error', globalErrorHandler);
